export * from "./array";
export * from "./layout";
export * from "./responseHelper";
export * from "./requestHelper";
export * from "./obj";
export * from "./authHelper";
export * from "./dateTimeHelper";
export * from "./uploadFileHelper";
export * from "./localStorageHelper";

export const isProduction = process.env.REACT_APP_ENV === "production";
export const isStaging = process.env.REACT_APP_ENV === "staging";
