import { isFulfilled, isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import {
    BaseQueryApi,
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { showCustomToast } from "../../components/common/ToastNotify";
import { postLogin, postLogout } from "../../features/authentication";
import { RES_STATUS, TOKEN_ERROR_KEY } from "../../types/enum";
import { IApiRes } from "../../types/interfaces";
import { getLocalValueByKey } from "../../utils";
import { RootState } from "../store";

// skip endpoints since different format in response
const customSkipEndpoints = [
    "uploadFile",
    "getUploadStatus",
    "uploadGreetingFile",
    "getAllProjectCharacters",
    "getReportHTML",
];

const baseUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:5005";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, api) => {
        const { getState, endpoint } = api;

        const isLogin = (getState() as RootState).auth.isLogin;

        const tbdToken = getLocalValueByKey("accessToken") || null;

        // if isLogin and have enterprise (TBD access token) then use it, or if isLogin and has tbdToken and the endpoint in injectEndpoint has custom Authorization, then use that token.
        if (isLogin && tbdToken && !customSkipEndpoints.includes(endpoint)) {
            let endpointHasToken = headers.get("Authorization");
            let token = endpointHasToken ? headers.get("Authorization") : "Bearer " + tbdToken;
            headers.set("Authorization", token!);
        }

        return headers;
    },
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);
    const customRes = result?.data as IApiRes;

    // accessToken expired handling
    if (customRes?.status === 0 && customRes?.message_key === "TOKEN.INVALID_ERROR") {
        console.log("accessToken expired");
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshToken = getLocalValueByKey("refreshToken") || null;

                const refreshResult: any = await baseQuery(
                    {
                        url: "/token_refresh",
                        method: "POST",
                        body: { refresh_token: refreshToken },
                    },
                    api,
                    extraOptions
                );

                if (refreshResult?.data?.status === 1 && refreshResult?.data?.data) {
                    console.log("refreshToken", {
                        accessToken: refreshResult?.data?.data?.access_token,
                        refreshToken: refreshResult?.data?.data?.refresh_token,
                        amazeAccessToken: refreshResult?.data?.data?.amaze_access_token,
                    });
                    api.dispatch(
                        postLogin({
                            accessToken: refreshResult?.data?.data?.access_token,
                            refreshToken: refreshResult?.data?.data?.refresh_token,
                            amazeAccessToken: refreshResult?.data?.data?.amaze_access_token,
                        })
                    );
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    console.log("refresh failed");
                    showCustomToast.error("請重新登入");

                    api.dispatch(postLogout());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

const baseQueryWithResHelper: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQueryWithReAuth(args, api, extraOptions);
    if (result?.error) {
        return result;
    }
    const customRes = result?.data as IApiRes;
    if (!(customRes?.status === RES_STATUS.SUCCESS) && !customSkipEndpoints?.includes(api?.endpoint)) {
        return {
            error: {
                status: customRes.status,
                data: { message: customRes?.message, message_key: customRes?.message_key },
            },
        };
    }
    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithResHelper,
    tagTypes: [
        "Auth",
        "Organization",
        "Account",
        "Role",
        "Project",
        "PoKnowledge",
        "Knowledge",
        "KRecords",
        "Password", // TODO : where would use this ?
        "WebBots",
        "JobStatus",
        "Config",
        "CodeList",
        "ProjectCharacter",
        "Plan",
        "PlanFuncBind",
        "Service",
        "Contract",
    ],
    endpoints: () => ({}),
});

export const rtkQueryErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    const skipTokenExpire =
        action?.payload?.data?.status !== RES_STATUS.SUCCESS &&
        (action?.payload?.data?.message_key === TOKEN_ERROR_KEY.ACCESS_TOKEN_EXPIRED ||
            action?.payload?.data?.message_key === TOKEN_ERROR_KEY.REFRESH_TOKEN_EXPIRED);

    // ignore all token expired error to show toast
    if (isRejectedWithValue(action) && !skipTokenExpire) {
        console.log("== 🐑  == rtkQueryErrorMiddleware: ", action?.payload);
        const { status, data } = action?.payload;
        const { message, message_key } = data;
        showCustomToast.error(message ?? "Something wrong");
    }

    return next(action);
};
