import { createSlice, Middleware } from "@reduxjs/toolkit";

import { RootState } from "../../../services/store";
import { checkIsSuperUser, getLocalValueByKey } from "../../../utils";
import { IAuthState } from "../types/interfaces";

const initialState: IAuthState = {
    currentUser: null,
    isLogin: !!getLocalValueByKey("accessToken"),
    //isAuth: "loading",
    //amazeAccessToken: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        postLogin: (state, action) => {
            state.isLogin = true;
            //state.isAuth = true;
        },
        postLogout: (state) => {
            state.isLogin = false;
            state.currentUser = null;
            //state.isAuth = false;
        },
        setCurrentUser: (state, action) => {
            //state.isAuth = true;
            state.currentUser = action.payload;
        },
        //setAmazeAccessToken: (state, action) => {
        //    state.amazeAccessToken = action.payload;
        //},
    },
});

export const { postLogin, postLogout, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectUserRoles = (state: RootState) => state.auth.currentUser?.roles;
export const selectIsLogin = (state: RootState) => state.auth.isLogin;
export const selectIsNoOrgsSuperUser = (state: RootState) =>
    checkIsSuperUser(state.auth.currentUser?.roles) && !getLocalValueByKey("amazeAccessToken");
//export const selectIsAuth = (state: RootState) => state.auth.isAuth;

export const tokenMiddleware: Middleware = (store) => (next) => (action) => {
    if (authSlice.actions.postLogin.type.match(action.type)) {
        const { accessToken, refreshToken, amazeAccessToken } = action.payload;
        if (accessToken && refreshToken && amazeAccessToken) {
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("amazeAccessToken", amazeAccessToken);
        } else {
            console.log("postLogin middleware", { accessToken, refreshToken, amazeAccessToken });
        }
    }
    if (authSlice.actions.postLogout.type.match(action.type)) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("amazeAccessToken");
    }
    return next(action);
};
